import React, { useState, useContext, useEffect, useCallback } from "react";
import { useAuth0 } from "../../utils/authentication";
import { LoadingOutlined } from '@ant-design/icons';
import { Divider, Button, Spin, Avatar, Typography, Row, Col, Table } from "antd";
import config from "../../config.json";
import InvoiceTable from "./InvoiceTable";
import CancelSubscription from "./CancelSubscription";
import { Link } from "react-router-dom";
import { AppContext, IAppState } from "../../AppState";
import ButtonGroup from "antd/lib/button/button-group";

import * as Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);

const Account = () => {
  const { token, sub, subscription }: IAppState = useContext(AppContext);
  const { loading, user }: any = useAuth0();

  const [invoices, setInvoices] = useState([]);
  const [fetching, setFetching] = useState(false);

  const fetchInvoices = useCallback(() => {
    if (!loading) {
      const cust_id =
        user["https://portal.salestribe.com/stripe/stripe_customer_id"];
      setFetching(true);
      if (token != null) {
        fetch(`${config.api.baseUrl}/invoices?id=${cust_id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        })
          .then(res => res.json())
          .then(body => {
            setFetching(false);
            setInvoices(body.data);
          });
      }
    }
  }, [loading, token, user]);

  useEffect(() => {
    fetchInvoices();
  }, [fetchInvoices, loading]);

  if (loading || !user) {
    return <div>Loading...</div>;
  }

  return (
    <div id="account-row">
      <Typography.Title level={2}>Account</Typography.Title>
      <Divider orientation="left">Personal details</Divider>
      <Row>
        <Col span={24}>
          <Avatar
            size="large"
            shape="circle"
            style={{ marginRight: "10px", width: "100px", height: "100px" }}
            src={user.picture}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <strong>{user.name}</strong>
          <br />
          {user.email}
        </Col>
      </Row>
      <Divider orientation="left">Subscription status</Divider>
      <SubscriptionTable subscription={subscription} />
      <div style={{ margin: "20px", textAlign: "center" }}>
        <ButtonGroup>
          <Button type="default" size="small">
            <Link to="/account/payment">Update payment details</Link>
          </Button>
          <CancelSubscription sub={sub} token={token} />
        </ButtonGroup>
      </div>
      <Divider orientation="left">Invoices</Divider>
      {!fetching ? (
        <InvoiceTable data={invoices} />
      ) : (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          />
        </div>
      )}
    </div>
  );
};

const SubscriptionTable = (props: any) => {
  const { subscription } = props;
  let cancellationFee = 0;

  let subscriptions = [];

  if (subscription) {
    subscriptions = props.subscription.Stripe.data;
  }

  return (
    <div id="subscription-table">
      <Table dataSource={subscriptions} rowKey="id" pagination={false}>
        <Table.Column title="Plan" dataIndex="plan.nickname" />
        <Table.Column
          title="Amount"
          dataIndex="plan.amount"
          render={amount => "$" + (amount / 100).toFixed(2)}
        />
        <Table.Column
          title="Started"
          dataIndex="start_date"
          render={date => new Date(date * 1000).toLocaleDateString()}
        />
        <Table.Column
          title="Next Invoice"
          dataIndex="current_period_end"
          render={(date, row: any) =>
            // new Date(date * 1000).toLocaleDateString()
            {
              if (row.cancel_at_period_end === true) {
                return <>None</>;
              } else {
                return (
                  <>
                    {new Date(
                      row.current_period_end * 1000
                    ).toLocaleDateString()}
                  </>
                );
              }
            }
          }
        />
        <Table.Column
          title="Status"
          dataIndex="status"
          render={(status, row: any) => {
            if (status === "active") {
              if (row.cancel_at_period_end === true) {
                return `Active until ${new Date(
                  row.cancel_at * 1000
                ).toLocaleDateString()}`;
              } else {
                return "Active";
              }
            } else {
              return "Cancelled";
            }
          }}
        />
        <Table.Column
          title="Cancellation Fee"
          render={(row: any) => {
            const monthlyRate = row.plan.amount;
            const startDate = moment(row.created * 1000);
            const endDate = moment(startDate).add(12, "months");
            const range = moment.range(startDate, endDate);

            const currentDate = moment();
            const dateInRange = range.contains(currentDate);

            // Calculate if the date is within the range, if so then we need to charge them a cancellation fee
            if (dateInRange && row.plan.interval === "month") {
              const dateDiff = endDate.diff(currentDate, "months");
              cancellationFee = (monthlyRate * dateDiff) / 100;
            }

            return "$" + cancellationFee;
          }}
        />
      </Table>
    </div>
  );
};

export default Account;
