import React, { useState, FC } from "react";
import { Button, Modal } from "antd";
import { Link } from "react-router-dom";
import { useAuth0 } from "../../utils/authentication";
import config from "../../config.json";

interface ICancelSubscriptionProps {
  sub?: boolean | null;
  token?: string | null;
}

const CancelSubscription: FC<ICancelSubscriptionProps> = ({ sub, token }) => {
  const [fetching, setFetching] = useState(false);

  const { loading, user }: any = useAuth0();

  const confirm = () => {
    Modal.confirm({
      title: "Are you sure you want to cancel your subscription?",
      content:
        "If paying monthly, you'll be billed the cancellation fee for the remaining months.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: cancelSubscription
    });
  };

  const cancelSubscription = async () => {
    if (!fetching && !loading) {
      const cust_id = user["https://portal.salestribe.com/stripe/stripe_customer_id"];
      setFetching(true);
      if (token != null && cust_id) {
        fetch(`${config.api.baseUrl}/subscription/cancel?id=${cust_id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        })
          .then(res => res.json())
          .then(body => {
            setFetching(false);
            window.location.reload();
          });
      }
    }
  };

  if (sub === null) return <></>;
  if (!sub) {
    return (
      <Button size="small" type="default">
        <Link to="/checkout">Subscribe</Link>
      </Button>
    );
  }
  return (
    <Button
      danger
      size="small"
      style={{ width: "150px" }}
      onClick={confirm}
      ghost
    >
      Cancel Subscription
    </Button>
  );
};

export default CancelSubscription;
