import React, {useContext} from "react";
import { useAuth0 } from "../../utils/authentication";
import ImportUsers from "./ImportUsers"
import { AppContext } from "../../AppState";

const BackOffice = () => {
    const {token} = useContext(AppContext)
    const { user }: any = useAuth0();
    const roles = user["https://portal.salestribe.com/claims/roles"];

    if (roles.includes("Back Office")) {
        return (
            <>
                <ImportUsers token={token} />
            </>
        )
    } else {
        return (
            <>
                Error
            </>
        )
    }
}

export default BackOffice;
