import React, { useEffect, FC } from 'react';
import { Route } from 'react-router-dom';
import { useAuth0 } from '../../utils/authentication';
import { Spin } from 'antd';
import { Redirect } from 'react-router-dom';

interface PrivateRouteProps {
    view: FC<any>;
    path?: string;
    sub?: boolean | null;
    exact?: boolean;
    withSubscription?: boolean;
}

const PrivateRoute: FC<PrivateRouteProps> = ({
    view: View,
    path,
    sub,
    withSubscription = true,
    ...rest
}) => {
    const {
        loading,
        isAuthenticated,
        loginWithRedirect,
        getTokenSilently
    }: any = useAuth0();

    useEffect(() => {
        if (loading || isAuthenticated) {
            return;
        }

        const fn = async () => {
            await loginWithRedirect();
        };
        fn();
    }, [
        loading,
        isAuthenticated,
        loginWithRedirect,
        path,
        getTokenSilently,
        sub
    ]);

    const render = (props: any) => {
        if (withSubscription) {
            if (
                sub !== undefined &&
                !sub &&
                !window.location.pathname.includes('checkout')
            )
                return <Redirect to="/checkout" />;
            if (
                sub !== undefined &&
                sub &&
                window.location.pathname.includes('checkout')
            ) {
                return <Redirect to="/" />;
            }
        }
        return isAuthenticated === true ? (
            <View {...props} />
        ) : (
            <div
                style={{
                    width: '100%',
                    height: 'calc(100vh - 250px)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Spin size="large" />
            </div>
        );
    };
    return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;
