import React, { useState, useContext } from "react";
import AssessmentInput from "./AssessmentInput";
import { Typography, Progress, Result, Spin, Button } from "antd";
import config from "../../config.json";
import { questions, length } from "../../constants/questions";
import { Link, Redirect } from "react-router-dom";
import { AppContext } from "../../AppState";
import { useAuth0 } from "../../utils/authentication";

const Assessment = () => {
  const {status, token, getPortalStatus} = useContext(AppContext)
  const [index, setIndex] = useState(0);
  const [input, setInput] = useState("");
  const [data, setData] = useState<any>({});
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { user }: any = useAuth0();
  const enterprise = config.enterprise.enabled;
  
  if (
    !enterprise &&
    !user["https://salestribe.com/user_metadata"]?.phone_number
  ) {
    return <Redirect to="/info" />;
  }

  const assessmentSubmitted = status
    ? status.assessmentSubmitted
      ? status.assessmentSubmitted
      : false
    : false;

  const next = (e: any) => {
    e.preventDefault();

    if (index === length - 1) {
      sendData({ ...data, [index + 1]: input });
    }

    setData({ ...data, [index + 1]: input });
    setIndex(index + 1);
    setInput(data[index + 2] ? data[index + 2] : "");
  };

  const back = () => {
    setInput(data[index]);
    setIndex(index - 1);
  };

  const sendData = (data: any) => {
    setLoading(true);

    let responses = [];
    for (const i in data) {
      const response = data[i];
      const index = parseInt(i) - 1;
      const question = questions[index].text;
      const category = questions[index].title;

      const responseObj = {
        response: response,
        question: question,
        category: category
      }

      responses.push(responseObj);
    }

    fetch(config.api.baseUrl + "/assessment", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(responses)
    })
    .then(res => res.json())
    .then(() => {
      setLoading(false);
      setSuccess(true);
      getPortalStatus.current();
    })
    .catch(() => {
      setLoading(false);
      setSuccess(false);
      setError(true);
    });
  };

  return assessmentSubmitted && !success ? (
    <Redirect to="/" />
  ) : (
      <>
        {!success && !error && (
          <div className="form-container">
            {loading && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "250px",
                  paddingBottom: "20px"
                }}
              >
                <Spin size="large"></Spin>
              </div>
            )}
            {!loading && index < length && (
              <>
                <Typography.Title>{questions[index].title}</Typography.Title>
                <AssessmentInput
                  placeholder=""
                  number={index + 1}
                  text={questions[index].text}
                  next={next}
                  value={input}
                  handleInput={(e: any) => setInput(e.target.value)}
                  back={back}
                ></AssessmentInput>
              </>
            )}
            <Progress
              strokeColor={{
                "0%": "rgba(111, 189, 43, 0.9)",
                "100%": "rgba(111, 189, 43, 0.9)"
              }}
              status="active"
              percent={Math.floor((index / length) * 100)}
            />
          </div>
        )}
        {success && (
          <Result
            status="success"
            title="Successfully submitted your assessment"
            subTitle="We'll review your answers and talk more with you about them in the 1-on-1 coaching session."
            extra={[
              <Button type="primary" key="console">
                <Link to="/">Home</Link>
              </Button>
            ]}
          />
        )}
        {error && !loading && (
          <Result
            status="error"
            title="Submission Failed"
            subTitle="Please check and modify the following information before resubmitting."
            extra={[
              <Button type="primary" key="console">
                <Link to="/assessment">Try Again</Link>
              </Button>
            ]}
          />
        )}
      </>
    );
};

export default Assessment;
