import { Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import config from "./config.json";
import { IStatus, IStatusResponse } from "./interfaces/IStatus";
import ISubscription from "./interfaces/ISubscription";
import { useAuth0 } from "./utils/authentication";

const AppContext = React.createContext<IAppState>({
  status: {
    assessment: false,
    coachingsession: false,
    learningmodules: false,
    profile: false
  }
});

export interface IAppState {
  sub?: boolean | null;
  token?: string | null;
  fetching?: boolean;
  status: IStatus;
  getPortalStatus?: any;
  validateSubscription?: any;
  subscription?: any;
  courses?: any;
}

const AppState = ({ children }: any) => {
  const [sub, setSub] = useState<boolean | null>(null);
  const [fetching, setFetching] = useState(true);
  const [token, setToken] = useState<string | null>(null);
  const [status, setStatus] = useState<IStatus>({
    assessment: false,
    coachingsession: false,
    learningmodules: false,
    profile: false
  });
  const [courses, setCourses] = useState<any>();
  const [subscription, setSubscription] = useState<any>();
  const validateSubscription = useRef<any>();
  const getPortalStatus = useRef<any>();
  const getLearningStatus = useRef<any>();

  const { isAuthenticated, getTokenSilently, loading, user }: any = useAuth0();

  validateSubscription.current = async (force: boolean = false) => {
    if (sub === null || force === true) {
      const cust_id = user["https://portal.salestribe.com/stripe/stripe_customer_id"];
      const response: ISubscription = await fetch(
        `${config.api.baseUrl}/subscription?id=${cust_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      ).then(res => res.json());

      if (response.Item) {
        setSub(response.Item.Status);
        setSubscription(response.Item);
      } else {
        setSub(false);
      }
    }
  };

  getPortalStatus.current = async () => {
    const response: IStatusResponse = await fetch(
      config.api.baseUrl + "/portal/status",
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    ).then(res => res.json());

    if (response && response.Item) {
      setStatus(response.Item);
    } else if (response) {
      setStatus({
        assessment: true,
        coachingsession: false,
        learningmodules: false,
        profile: false
      });
    }
    setFetching(false);
  };

  getLearningStatus.current = async () => {
    if (sub === null || sub === false) { // If no subscription, don't check the courses
    } else { // If there is a subscription, check the courses
      const response: IStatusResponse = await fetch(
        config.api.baseUrl + "/moodle/courses",
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      ).then(res => res.json());

      if (response && !courses) {
        setCourses(response);
      }
    }
  }

  const getToken = async () => setToken(await getTokenSilently());

  useEffect(() => {
    if (!loading && isAuthenticated) {
      getToken();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, isAuthenticated]);

  useEffect(() => {
    if (token) {
      validateSubscription.current();
      getLearningStatus.current();
      getPortalStatus.current();
    }
  }, [token])

  if (sub === null || loading) {
    return (
      <>
        <div
          style={{
            width: "100%",
            height: "calc(100vh - 250px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Spin size="large" />
        </div>
      </>
    )
  }

  return (
    <AppContext.Provider
      value={{
        sub,
        token,
        status,
        fetching,
        getPortalStatus,
        validateSubscription,
        subscription,
        courses
      }}
    >
      {children}
    </AppContext.Provider>
  )
};

export default AppState;
export { AppContext };