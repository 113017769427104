import 'antd/dist/antd.less';
import './assets/css/main.css';

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Auth0Provider } from "./utils/authentication";
import config from "./config.json";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";

serviceWorker.register();
// A function that routes the user to the right place
// after login
const onRedirectCallback = () => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
  }
}

let auth0ClientId = config.auth.clientId;
if (config.enterprise.enabled) {
  auth0ClientId = config.enterprise.auth.clientId;
}

ReactDOM.render(
  <Auth0Provider
    domain={config.auth.domain}
    client_id={auth0ClientId}
    redirect_uri={window.location.origin}
    audience={config.auth.audience}
    onRedirectCallback={onRedirectCallback}
  >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Auth0Provider>,
  document.getElementById("root")
);
