import React, { useContext } from "react";
import Section from "./Section";
import QuickLinks from "./QuickLinks";
import config from "../../config.json";
import { Link } from "react-router-dom";
import { AppContext } from "../../AppState";
import { LikeFilled } from "@ant-design/icons";
import { Row, Divider, Button } from "antd";

const Portal = () => {
  const value = useContext(AppContext);
  const {
    fetching: loading,
    status: { assessment, coachingsession, assessmentSubmitted }
  } = value;
  // const education = value.status && value.status.learningmodules;

  const enterprise = config.enterprise.enabled;

  return (
    <Row id="portal-row" gutter={24}>
      <QuickLinks />
      {enterprise ? (
        <div style={{ alignSelf: "center" }}>
          <Section
            enterprise
            subscribed={true}
            loading={loading}
            number={1}
            available={true}
            title="Sales Success with Social Media"
            body="This program is a guide to 'modern selling' best practices using social media. The program will teach you how to use social media the right way - to dramatically improve your business outcomes, and enhance your career."
            buttonText="Get started"
            buttonUrl="https://salestribe.auth0.com/samlp/aZe6Svv5ZHqb1Opdp5PGvh95hb0T0Pon"
            external
            icon="read"
            courses={value.courses}
          />
        </div>
      ) : (
        <div style={{ width: "100%", paddingLeft: 10, paddingRight: 10 }}>
          <Section
            completed={assessmentSubmitted}
            loading={loading}
            available={assessment || value.status === {}}
            number={1}
            title="Assessment"
            body="Your first step to becoming SalesTribe certified is to complete our assessment questionnaire."
            buttonText="Get started"
            buttonUrl="/assessment"
            icon="audit"
            subscribed
          />
          {value.sub === false && (
            <Divider orientation="center">
              <Link to="/checkout">
                <Button type="primary" size="large">
                  <LikeFilled /> Subscribe to access courses and coaching
                </Button>
              </Link>
            </Divider>
          )}
          <Section
            subscribed={value.sub}
            loading={loading}
            available={value.sub === true}
            number={2}
            title="Education - Sales Success with Social Media"
            body="This program is a guide to 'modern selling' best practices using social media. The program will teach you how to use social media the right way - to dramatically improve your business outcomes, and enhance your career."
            buttonText="Learn"
            buttonUrl="https://salestribe.auth0.com/samlp/yNE8OSmhmXENnqmi3Gm4V3xJ5C0Di1r7"
            external
            icon="read"
            courses={value.courses}
          />
          <Section
            subscribed={value.sub}
            loading={loading}
            available={coachingsession && value.sub === true}
            number={3}
            title="Coach"
            body="Book a time for one on one coaching with our SalesTribe team.  They'll go over your assessment and field your questions."
            buttonText="Book"
            coach
            icon="notification"
          />
        </div>
      )}
    </Row>
  );
};

export default Portal;
