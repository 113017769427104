import config from "../config.json";
import Events from "../enums/Events";

export const load = () => {
  window.analytics.load(config.segment.writeKey);
};
export const page = () => {
  window.analytics.page();
};
export const track = (name: Events, properties?: any) => {
  window.analytics.track(name, properties);
};

export const identify = (userId: string, traits?: any, options?: any) => {
  window.analytics.identify(userId, traits, options);
};
