import React, { useState, useEffect, FC } from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import { useAuth0 } from "../../utils/authentication";
import { DollarOutlined } from '@ant-design/icons';
import {
  Button,
  Input,
  Radio,
  Typography,
  Result,
  Divider,
  Statistic,
  message,
} from "antd";
import config from "../../config.json";
import { MONTHLY_PRICE, YEARLY_PRICE } from '../../constants/PlanPricing';
import EmailImage from "../../assets/images/verify-email.svg";
import { Link } from "react-router-dom";
import { IAppState } from "../../AppState";
import { track } from '../../utils/analytics';
import Events from '../../enums/Events';

interface ICheckoutFormProps {
  stripe?: any;
  value: IAppState;
}

const CheckoutForm: FC<ICheckoutFormProps> = ({ stripe, value }) => {
  const { user }: any = useAuth0();
  let userGivenName = "";
  let userFamilyName = "";

  const urlParams = new URLSearchParams(window.location.search);
  const planParam = urlParams.get('plan');

  if (
    typeof user.given_name !== "undefined" &&
    typeof user.family_name !== "undefined"
  ) {
    userGivenName = user.given_name;
    userFamilyName = user.family_name;
  }

  const [cardholderName, setCardholderName] = useState("");
  const [firstName, setFirstName] = useState(userGivenName);
  const [lastName, setLastName] = useState(userFamilyName);
  const [plan, setPlan] = useState(0);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [verified, setVerified] = useState(true);
  const [sendingEmail, setSendingEmail] = useState(false);

  const submit = async (ev: any) => {
    ev.preventDefault();
    setLoading(true);
    const cust_id = user["https://portal.salestribe.com/stripe/stripe_customer_id"];

    if (planParam === "free") {
      // sendPaymentData({ id: "free" }, cust_id, value.token);
    } else {
      await stripe
      .createPaymentMethod("card", {
        billing_details: { name: cardholderName }
      })
      .then(async ({ paymentMethod }: stripe.PaymentMethodResponse) => {
        if (paymentMethod) {
          sendPaymentData(paymentMethod, cust_id, value.token);
        } else {
          message.error('Your payment details are invalid, please try again.');
          setLoading(false);
        }
      });
    }
  };

  const sendPaymentData = (pm: any, cust_id: string, token: any) => {
    fetch(config.api.baseUrl + "/subscription", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      },
      method: "POST",
      body: JSON.stringify({
        pm: { id: pm.id },
        plan_choice: plan,
        cust_id,
        first_name: firstName,
        last_name: lastName,
        id: user.sub
      })
    })
      .then(res => res.json())
      .then(body => {
        setLoading(false);
        if (body.errorMessage || body.message === "Failed") {
          track(Events.SubscriptionError);
          setSuccess(false);
          setError(true);
        } else {
          track(Events.Subscribed);
          setError(false);
          setSuccess(true);
        }
      });
  };

  const sendVerificationEmail = () => {
    if (!loading) {
      setSendingEmail(true);
      fetch(config.api.baseUrl + "/resendVerification", {
        headers: {
          Authorization: `Bearer ${value.token}`,
          "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify({
          id: user.sub
        })
      })
        .then(res => res.json())
        .then(body => body)
        .then(() => {
          setSendingEmail(false);
          return message.success(
            "Verification email sent, please check your inbox"
          );
        });
    }
  };

  useEffect(() => {
    if (user) setVerified(user.email_verified);
  }, [user]);

  useEffect(() => {
    window.onbeforeunload = () => track(Events.UserAborted);
  }, []);

  // if (planParam === 'free') {
  //   return (
  //     <>
  //       <div className="content-container">
  //         <form onSubmit={submit} className="checkout">
  //           <Radio.Group
  //             size="large"
  //             onChange={e => setPlan(e.target.value)}
  //             value={plan}
  //             style={{ width: "100%" }}
  //           >
  //             <Radio.Button style={{ width: "100%" }} value={2}>
  //               Free Plan
  //             </Radio.Button>
  //           </Radio.Group>
  //           <Input.Group>
  //             <Input
  //               size="large"
  //               style={{ width: "50%", borderRight: 0 }}
  //               required
  //               onChange={e => setFirstName(e.target.value)}
  //               value={firstName}
  //               placeholder="First name"
  //             />
  //             <Input
  //               size="large"
  //               style={{ width: "50%" }}
  //               required
  //               onChange={e => setLastName(e.target.value)}
  //               value={lastName}
  //               placeholder="Last name"
  //             />
  //           </Input.Group>
  //           <Button
  //             style={{ marginLeft: "5px" }}
  //             loading={loading}
  //             type="primary"
  //             htmlType="submit"
  //             icon="dollar"
  //           >
  //             Subscribe
  //           </Button>
  //         </form>
  //       </div>
  //     </>
  //   )
  // }

  const planContentMonthly = (
    <div>
      <p>This plan includes all our great content, such as:</p>
      <ul>
        <li>Career Assessment</li>
        <li>Online Courses</li>
        <li>One-on-One coaching sessions</li>
      </ul>
      <p>
        <em>Minimum cost for the 12 month term is $468, split between 12 monthly payments of $39.</em>
      </p>
    </div>
  );

  // const planContentYearly = (
  //   <div>
  //     <p><strong>Save $78!</strong></p>
  //     <p>This plan includes all our great content, such as:</p>
  //     <ul>
  //       <li>Career Assessment</li>
  //       <li>Online Courses</li>
  //       <li>One-on-One coaching sessions</li>
  //     </ul>
  //     <p>
  //       <em>Minimum cost for the 12 month term is $390 in one upfront payment.</em>
  //     </p>
  //   </div>
  // );

  return (
    <div className="content-container">
      <Typography.Title level={2}>
        {verified ? "Payment details" : "Verify email"}
      </Typography.Title>
      <div className="checkout-container">
        {!success && !error && (
          <>
            {!verified && (
              <div>
                <Result
                  status={"info"}
                  icon={
                    <img
                      style={{ maxWidth: "250px" }}
                      src={EmailImage}
                      alt="Verify Email"
                    ></img>
                  }
                  title="We've sent you a link to verify your email"
                  subTitle="Didn't get it? Don't forget to check your junk folder."
                  extra={[
                    <Button
                      key="resend"
                      type="default"
                      loading={sendingEmail}
                      disabled={sendingEmail}
                      onClick={() => sendVerificationEmail()}
                    >
                      Resend
                    </Button>,
                    <Button
                      key="continue"
                      type="primary"
                      onClick={() => window.location.reload()}
                    >
                      Continue
                    </Button>
                  ]}
                />
              </div>
            )}
            {verified && (
              <form onSubmit={submit} className="checkout">
                <Radio.Group
                  size="large"
                  onChange={e => setPlan(e.target.value)}
                  value={plan}
                  style={{ width: "100%" }}
                >
                    <Radio.Button className="plan-button" value={0} style={{ width: '100%' }}>
                      {/* <Popover placement="bottom" content={planContentMonthly}> */}
                        <strong>Annual Plan, Paid Monthly</strong> 
                        {/* <Icon type="info-circle" /> */}
                      {/* </Popover> */}
                    </Radio.Button>
                    {/* <Radio.Button className="plan-button" value={1}>
                      <Popover placement="bottom" content={planContentYearly}>
                        <strong>Annual Plan, Paid Yearly</strong> <Icon type="info-circle" />
                      </Popover>
                    </Radio.Button> */}
                    <br /><br />{planContentMonthly}
                </Radio.Group>
                <Divider orientation="left">Confirm your name</Divider>
                <Input.Group>
                  <Input
                    size="large"
                    style={{ width: "50%", borderRight: 0 }}
                    required
                    onChange={e => setFirstName(e.target.value)}
                    value={firstName}
                    placeholder="First name"
                  />
                  <Input
                    size="large"
                    style={{ width: "50%" }}
                    required
                    onChange={e => setLastName(e.target.value)}
                    value={lastName}
                    placeholder="Last name"
                  />
                </Input.Group>
                <Divider orientation="left">Card details</Divider>
                <Input
                  size="large"
                  required
                  onChange={e => setCardholderName(e.target.value)}
                  value={cardholderName}
                  placeholder="Cardholder Name"
                />

                <CardElement />
                <Divider />
                <div className="payment-confirm">
                  <div className="payment-confirm-info">
                    <Typography.Title level={4} style={{ margin: '0 10px 0 0' }}>
                      Recurring Total:
                    </Typography.Title>
                    <Statistic
                      title=""
                      value={plan ? YEARLY_PRICE : MONTHLY_PRICE}
                      style={{marginRight: '5px'}}
                    />
                    {plan === 0 ? (
                      <>
                        /month
                      </>
                    ) : (
                      <>
                        /year
                      </>
                    )}
                  </div>
                </div>
                <div>
                  <Button
                    style={{ marginLeft: "5px" }}
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    icon={<DollarOutlined />}
                  >
                    Subscribe
                  </Button>
                </div>
                <div>
                  <p style={{textAlign:'center', paddingTop: '30px'}}>
                    By clicking subscribe, you agree to our <a href="https://salestribe.com/terms/" target="_blank" rel="noopener noreferrer">terms and conditions</a>.
                  </p>
                </div>
              </form>
            )}
          </>
        )}
        {success && (
          <Result
            status="success"
            title="Successfully Subscribed!"
            subTitle="Congrats! You have successfully subscribed to SalesTribe Portal"
            extra={[
              <Button type="primary" key="console">
                <Link
                  to="/checkout"
                  onClick={() => value.validateSubscription.current(true)}
                >
                  Home
                </Link>
              </Button>
            ]}
          />
        )}
        {error && (
          <Result
            status="error"
            title="Subscription Failed"
            subTitle="Please check your information before resubmitting."
            extra={[
              <Button
                onClick={() => setError(false)}
                type="primary"
                key="console"
              >
                Try Again
              </Button>
            ]}
          ></Result>
        )}
      </div>
    </div>
  );
};

export default injectStripe(CheckoutForm);
