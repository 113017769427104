import React, { FC } from "react";
import { CheckCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Divider, Typography, Spin, Button } from "antd";

const moment = require('moment');

interface ICompletedSectionProps {
  number: number;
  title: string;
  loading?: boolean;
  completed: string;
}

const CompletedSection: FC<ICompletedSectionProps> = ({
  number,
  title,
  loading,
  completed
}) => {
  return (
    <div
      className="section section-completed"
      style={{
        transition: "0.3s all",
        position: "relative"
      }}
    >
      <h1 className="number">{number}</h1>
      <Divider
        type="vertical"
        style={{
          height: "150px",
          marginRight: "35px"
        }}
      />
      <div>
        <Typography.Title level={2}>{title}</Typography.Title>
        {loading ? (
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          />
        ) : (
          <Button
            disabled
            type="link"
            size="large"
            style={{ padding: 0, fontSize: "18px" }}
          >
            Completed on {moment(completed).format('LL')}
          </Button>
        )}
      </div>
      <CheckCircleOutlined
        style={{
          transition: "0.5s all",
          color: "rgba(145, 200, 72, 0.9)",
          position: "absolute",
          fontSize: "52px",
          top: 0,
          right: 0,
          marginTop: "50px",
          marginRight: "35px"
        }} />
    </div>
  );
};

export default CompletedSection;
