import React from "react";
import { useAuth0 } from "../../utils/authentication";
import { Typography, Avatar, Divider } from "antd";
const { Title } = Typography;

const moment = require("moment");

const QuickLinks = () => {
  const { user }: any = useAuth0();

  const greetingText = () => {
    const currentTime = moment().local();

    if (!currentTime || !currentTime.isValid()) {
      return "Hello!";
    }

    const splitAfternoon = 12; // 24hr time to split the afternoon
    const splitEvening = 17; // 24hr time to split the evening
    const currentHour = parseFloat(currentTime.format("HH"));

    if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
      // Between 12 PM and 5PM
      return "Good Afternoon!";
    } else if (currentHour >= splitEvening) {
      // Between 5PM and Midnight
      return "Good Evening!";
    }
    // Between dawn and noon
    return "Good Morning!";
  };

  return (
    <>
      <div style={{ width: "100%", textAlign: "center" }}>
        <Avatar
          shape="circle"
          style={{ width: "100px", height: "100px", marginBottom: "20px" }}
          src={user.picture}
        />

        <div>Welcome, {user.given_name}</div>
        <Title level={2}>{greetingText()}</Title>

        <Divider style={{ marginTop: "30px", marginBottom: "30px" }} />
      </div>
    </>
  );
};

export default QuickLinks;
