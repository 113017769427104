import React, { useEffect, useState } from 'react';
import { Switch, withRouter, RouteComponentProps } from 'react-router-dom';
import Routes from './constants/Routes';
import { load, page } from './utils/analytics';
import config from './config.json';

import NavBar from './components/common/NavBar';
import PrivateRoute from './components/common/PrivateRoute';
import SiteFooter from './components/common/SiteFooter';
import NotFound from './components/common/404';

import Account from './components/account/Account';
import Checkout from './components/checkout/Checkout';
import Portal from './components/portal/Portal';
import Assessment from './components/assessment/Assessment';
import UpdatePaymentWrapper from './components/account/UpdatePaymentWrapper';
import BackOffice from './components/backoffice/BackOffice';
import InfoWizard from './components/infowizard/InfoWizard';

import AppState from './AppState';
import { AppContext, IAppState } from './AppState';

import { Layout } from 'antd';
const { Content } = Layout;

interface IAppProps extends RouteComponentProps<any> {}

const App: React.FC<IAppProps> = ({ history }) => {
    const [analyticsLoaded, setAnalyticsLoaded] = useState<boolean>(false);
    
    useEffect(() => {
        if (!analyticsLoaded) {
            load();
            setAnalyticsLoaded(true);
        }
    }, [analyticsLoaded]);

    useEffect(() => {
        if (analyticsLoaded) history.listen(page);
    }, [history, analyticsLoaded]);

    const enterprise = config.enterprise.enabled

    return (
        <div className="App">
            <Layout>
                <header>
                    <NavBar enterprise={enterprise} />
                </header>
                <Content style={{ background: '#fff' }}>
                    <Layout
                        style={{
                            margin: '0 auto',
                            maxWidth: '1260px',
                            padding: '24px 0',
                            background: '#fff'
                        }}
                    >
                        <AppState>
                            <AppContext.Consumer>
                                {(value: IAppState) => (
                                  enterprise ? <Switch>
                                        <PrivateRoute
                                            withSubscription={false}
                                            sub={true}
                                            exact
                                            path={Routes.HOME}
                                            view={Portal}
                                        />
                                        <PrivateRoute
                                            exact
                                            path={Routes.BACKOFFICE}
                                            view={BackOffice}
                                        />
                                        <PrivateRoute view={NotFound} />
                                  </Switch> :
                                    <Switch>
                                        <PrivateRoute
                                            withSubscription={false}
                                            sub={value.sub}
                                            exact
                                            path={Routes.HOME}
                                            view={Portal}
                                        />
                                        <PrivateRoute
                                            withSubscription={false}
                                            sub={value.sub}
                                            path={Routes.ASSESSMENT}
                                            view={Assessment}
                                        />
                                        <PrivateRoute
                                            sub={value.sub}
                                            path={Routes.CHECKOUT}
                                            view={Checkout}
                                        />
                                        <PrivateRoute
                                            exact
                                            path={Routes.ACCOUNT}
                                            view={Account}
                                        />
                                        <PrivateRoute
                                            sub={value.sub}
                                            path={Routes.UPDATE_PAYMENT}
                                            view={UpdatePaymentWrapper}
                                        />
                                        <PrivateRoute
                                            exact
                                            path={Routes.BACKOFFICE}
                                            view={BackOffice}
                                        />
                                        <PrivateRoute
                                            exact
                                            path={Routes.INFO_WIZARD}
                                            view={InfoWizard}
                                        />
                                        <PrivateRoute view={NotFound} />
                                    </Switch>
                                )}
                            </AppContext.Consumer>
                        </AppState>
                    </Layout>
                </Content>
                <AppState>
                    <SiteFooter />
                </AppState>
            </Layout>
        </div>
    );
};

export default withRouter(App);
