import React, { useState, useContext } from "react";
import "./InfoWizard.css";
import { Form, Select, Input, Button, Typography, message } from "antd";
import { useAuth0 } from "../../utils/authentication";
import config from "../../config.json";
import { AppContext } from "../../AppState";

const InfoWizard = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const { user }: any = useAuth0();

  const isSocial = !user.sub.startsWith("auth0");

  const { given_name, family_name, sub } = user;

  const { token } = useContext(AppContext);

  const prefixSelector = (
    <Form.Item name="phone_number_prefix" noStyle>
      <Select style={{ width: 70 }}>
        <Select.Option value="+61">+61</Select.Option>
      </Select>
    </Form.Item>
  );

  const onFinish = async (values: any) => {
    setLoading(true);
    const url = isSocial ? "/userInfoSocial" : "/userInfo";
    const resp = await fetch(config.api.baseUrl + url, {
      method: "post",
      body: JSON.stringify({ ...values, id: sub }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then(data => data.json());

    setLoading(false);
    if (resp.statusCode !== 200) {
      return message.error("Something went wrong, please try again.");
    }

    window.location.pathname = "/assessment";
  };

  return (
    <div className="infoWizardContainer">
      <Typography.Title level={3}>About you</Typography.Title>
      <Form
        initialValues={{ phone_number_prefix: "+61", given_name, family_name }}
        className="infoWizardForm"
        onFinish={onFinish}
        form={form}
      >
        {!isSocial && (
          <>
            <Form.Item
              rules={[{ required: true }]}
              name="given_name"
              label="First name"
            >
              <Input placeholder="John" />
            </Form.Item>
            <Form.Item
              rules={[{ required: true }]}
              name="family_name"
              label="Last name"
            >
              <Input placeholder="Smith" />
            </Form.Item>
          </>
        )}
        <Form.Item
          rules={[{ required: true }]}
          name="phone_number"
          label="Phone number"
        >
          <Input
            type="tel"
            maxLength={15}
            placeholder="412345678"
            addonBefore={prefixSelector}
          />
        </Form.Item>
        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Continue
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default InfoWizard;
