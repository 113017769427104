const HOME = "/";
const CHECKOUT = "/checkout";
const ACCOUNT = "/account";
const UPDATE_PAYMENT = "/account/payment";
const PROFILE = "/profile";
const NEWS = "/news";
const ASSESSMENT = "/assessment";
const BACKOFFICE = "/backoffice";
const INFO_WIZARD = "/info";

export default {
  HOME,
  CHECKOUT,
  ACCOUNT,
  UPDATE_PAYMENT,
  PROFILE,
  NEWS,
  ASSESSMENT,
  BACKOFFICE,
  INFO_WIZARD
};
