import React, { useEffect } from "react";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";
import { track } from '../../utils/analytics';
import Events from '../../enums/Events';

const NotFound = () => {
  useEffect(() => {
    track(Events.PageNotFound);
  }, [])

  return (
    <div className="content-container">
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button type="primary">
            <Link to="/">Home</Link>
          </Button>
        }
      />
    </div>
  );
};

export default NotFound;
