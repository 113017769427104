import React, { FC, useState } from "react";
import { LoadingOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Typography, Button, Spin, Divider } from "antd";
import Schedule from "./Schedule";
import { Link } from "react-router-dom";
import CompletedSection from "./CompletedSection";
import { track } from "../../utils/analytics";
import Events from "../../enums/Events";

interface ISectionProps {
  available?: boolean;
  number: any;
  title: string;
  body: string;
  buttonText: string;
  icon: string;
  buttonUrl?: string;
  external?: boolean;
  coach?: boolean;
  loading?: boolean;
  completed?: string;
  courses?: object;
  subscribed?: boolean | null | undefined;
  enterprise?: boolean;
}

const Courses = (props: any) => {
  const { courses } = props;

  if (courses.length === 0) {
    return <></>;
  }

  return (
    <>
      <strong>Courses in Progress</strong>
      <ul>
        {courses.map((item: any, i: any) =>
          item.completed ? false : <li key={i}>{item.fullname}</li>
        )}
      </ul>
    </>
  );
};

const Section: FC<ISectionProps> = ({
  available,
  number,
  title,
  body,
  buttonText,
  icon,
  buttonUrl = "/",
  external = false,
  coach = false,
  loading,
  completed,
  courses,
  subscribed,
  enterprise = false
}) => {
  const [hover, setHover] = useState(false);

  if (completed && !loading) {
    return (
      <CompletedSection
        number={number}
        title={title}
        loading={loading}
        completed={completed}
      />
    );
  }

  const component = (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className="section"
      style={{
        cursor: subscribed === false ? "pointer" : "default",
        transition: "0.3s all",
        border: enterprise ? "1px solid rgb(225, 225, 225)" : "", 
        position: "relative"
      }}
    >
      {!enterprise && (
        <>
          <h1
            style={{ color: available ? "rgba(145, 200, 72, 0.9)" : "" }}
            className="number"
          >
            {number}
          </h1>
          <Divider
            type="vertical"
            style={{
              height: "200px",
              marginRight: "35px"
            }}
          />
        </>
      )}
      <div style={{ width: "70%" }}>
        <Typography.Title level={2}>{title}</Typography.Title>
        <p
          style={{
            fontSize: "18px",
            color: subscribed === false ? "rgba(0, 0, 0, 0.65)" : "inherit"
          }}
        >
          {body}
        </p>
        {courses && subscribed === true && <Courses courses={courses} />}
        {loading ? (
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          />
        ) : (
          <Button
            disabled={!available}
            type={enterprise ? "primary" : "link"}
            size="large"
            style={{ fontSize: "18px" }}
          >
            {external && !coach && (
              <a
                href={buttonUrl}
                onClick={() =>
                  track(Events.ExternalLink, {
                    buttonText,
                    buttonUrl
                  })
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {buttonText}
              </a>
            )}
            {!external && !coach && <Link to={buttonUrl}>{buttonText}</Link>}
            {coach && <Schedule />}
          </Button>
        )}
      </div>
      {icon &&
        (subscribed === false ? (
          <LegacyIcon
            type={hover ? "unlock" : "lock"}
            style={{
              transition: "0.5s all",
              color: hover ? "rgba(145, 200, 72, 0.9)" : "#e1e1e1e1",
              position: "absolute",
              fontSize: "52px",
              top: 0,
              right: 0,
              marginTop: "30px",
              marginRight: "35px"
            }}
          />
        ) : (
          <LegacyIcon
            style={{
              transition: "0.5s all",
              color: available ? "rgba(145, 200, 72, 0.9)" : "#e1e1e1e1",
              position: "absolute",
              fontSize: "52px",
              top: 0,
              right: 0,
              marginTop: "30px",
              marginRight: "35px"
            }}
            type={icon}
          />
        ))}
    </div>
  );

  return subscribed ? component : <Link to="/checkout">{component}</Link>;
};

export default Section;
