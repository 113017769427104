import React, { useState, FC } from "react";
import { LockOutlined } from '@ant-design/icons';
import { Input, Divider, Button, Typography, Result } from "antd";
import { CardElement, injectStripe } from "react-stripe-elements";
import { useAuth0 } from "../../utils/authentication";
import config from "../../config.json";
import { Link } from "react-router-dom";
import { IAppState } from "../../AppState";

interface IUpdatePaymentProps {
  stripe?: any;
  value: IAppState;
}

const UpdatePayment: FC<IUpdatePaymentProps> = ({ stripe, value }) => {
  const [cardholderName, setCardholderName] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const { user }: any = useAuth0();

  const submit = async (ev: any) => {
    ev.preventDefault();
    setLoading(true);
    const cust_id = user["https://portal.salestribe.com/stripe/stripe_customer_id"];

    await stripe
      .createPaymentMethod("card", {
        billing_details: { name: cardholderName }
      })
      .then(async ({ paymentMethod }: any) => {
        if (paymentMethod) {
          sendPaymentData(paymentMethod, cust_id, value.token);
        } else return { error: "failed to create payment method" };
      });
  };

  const sendPaymentData = (pm: any, cust_id: string, token: any) => {
    fetch(config.api.baseUrl + "/updatePayment", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      },
      method: "POST",
      body: JSON.stringify({
        pm: { id: pm.id },
        cust_id
      })
    })
      .then(res => res.json())
      .then(body => {
        setLoading(false);
        if (body.errorMessage) {
          setSuccess(false);
          setError(true);
        } else {
          setError(false);
          setSuccess(true);
        }
      });
  };

  return (
    <div className="content-container">
      <Typography.Title level={2}>Update Payment Details</Typography.Title>
      <div className="checkout-container">
        {!success && !error && (
          <form onSubmit={submit} className="checkout">
            <Divider style={{ marginTop: 0 }} orientation="left">
              Card details
            </Divider>
            <Input
              size="large"
              required
              onChange={e => setCardholderName(e.target.value)}
              value={cardholderName}
              placeholder="Cardholder Name"
            />

            <CardElement />
            <Button
              style={{ width: "100px", marginLeft: "5px" }}
              loading={loading}
              type="primary"
              htmlType="submit"
              icon={<LockOutlined />}
            >
              Update
            </Button>
          </form>
        )}
        {success && (
          <Result
            status="success"
            title="Successfully Updated!"
            subTitle="Thank you, you have successfully updated your payment details"
            extra={[
              <Button type="primary" key="console">
                <Link to="/">Home</Link>
              </Button>,
              <Button type="default" key="console">
                <Link to="/account">Back</Link>
              </Button>
            ]}
          />
        )}
        {error && (
          <Result
            status="error"
            title="Update failed"
            subTitle="Please check your information before resubmitting."
            extra={[
              <Button
                onClick={() => setError(false)}
                type="primary"
                key="console"
              >
                Try Again
              </Button>
            ]}
          ></Result>
        )}
      </div>
    </div>
  );
};

export default injectStripe(UpdatePayment);
