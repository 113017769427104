enum Events {
  ButtonClicked = "Button Clicked",
  ExternalLink = "External Link Clicked",
  BookCoachClicked = "Booked Coach Button Clicked",
  SubscriptionError = "Subscription Failed",
  Subscribed = "Successfully Subscribed",
  UserAborted = "Subscription Aborted",
  PageNotFound = "Page not found"
}

export default Events;
