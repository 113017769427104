import React, { useState, useContext } from "react";
import { AppContext } from "../../AppState";
import config from "../../config.json";
import { Button, Modal, Input, message } from "antd";
import {
  CalendarFilled,
  FacebookFilled,
  LinkedinFilled,
  TwitterSquareFilled,
  YoutubeFilled
} from "@ant-design/icons";

const SiteFooter: React.FC = () => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState("");

  const { token } = useContext(AppContext);

  const submit = () => {
    setLoading(true);
    fetch(config.api.baseUrl + "/feedback", {
      method: "post",
      body: JSON.stringify(feedback),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then(() => {
      setModal(false);
      setLoading(false);
      setFeedback("");
      message.success("Thanks for your feedback!");
    });
  };

  return (
    <footer
      id="footer"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: "white"
      }}
    >
      <p>© SalesTribe 2020</p>
      <Modal
        okText="Send"
        okButtonProps={{ loading: loading, disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
        onCancel={() => setModal(false)}
        onOk={submit}
        title="Give us feedback"
        visible={modal}
      >
        <Input.TextArea
          onChange={e => setFeedback(e.target.value)}
          value={feedback}
        />
      </Modal>
      <Button onClick={() => setModal(true)} type="link">
        Give us feedback
      </Button>

      <ul className="quicklinks">
        <li>
          <CalendarFilled />
          <a
            href="https://salestribe.com/events/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Events
          </a>
        </li>
        <li>
          <LinkedinFilled />
          <a
            href="https://www.linkedin.com/company/salestribe.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
          </a>
        </li>
        <li>
          <YoutubeFilled />
          <a
            href="https://www.youtube.com/channel/UCToxQnBsvBmQOhPrG_gAJ-Q"
            target="_blank"
            rel="noopener noreferrer"
          >
            YouTube
          </a>
        </li>
        <li>
          <FacebookFilled />
          <a
            href="https://www.facebook.com/salestribeint/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook
          </a>
        </li>
        <li>
          <TwitterSquareFilled />
          <a
            href="https://twitter.com/SalesTribeInt"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
        </li>
      </ul>
    </footer>
  );
};

export default SiteFooter;
