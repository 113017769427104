import React, { useState, useEffect, useContext } from "react";
import { Modal } from "antd";
import { Link } from "react-router-dom";
import { track } from "../../utils/analytics";
import Events from "../../enums/Events";
import config from "../../config.json";
import { AppContext, IAppState } from "../../AppState";

const Schedule = () => {
  const { token }: IAppState = useContext(AppContext);
  const [visible, setVisible] = useState(false);
  const [timer, setTimer] = useState(0);

  const getScript = () => {
    const script = document.createElement("script");
    script.src =
      "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
    script.async = true;
    document.body.appendChild(script);
  };

  useEffect(() => {
    if (visible) {
      setTimeout(function () {
        setTimer(timer + 1);
      }, 1000);
    }
  });

  const showModal = () => {
    getScript();
    setVisible(true);
    track(Events.BookCoachClicked);
  };

  const closeWindow = async () => {
    if (timer >= 10) {
      fetch(config.api.baseUrl + "/portal/status", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        },
        method: "PUT",
        body: JSON.stringify({
          profile: true
        })
      }).then(res => res.json());
    }

    setVisible(false);
  };

  return (
    <>
      <Link to="/" type="primary" onClick={showModal}>
        Book
      </Link>
      <Modal
        title="Book a session"
        visible={visible}
        onCancel={() => closeWindow()}
        footer={null}
      >
        <div
          className="meetings-iframe-container"
          data-src="https://app.hubspot.com/meetings/graham11/30-min?embed=true"
        ></div>
        <div style={{ textAlign: "center" }}>
          Please close this window after booking is complete.
        </div>
      </Modal>
    </>
  );
};

export default Schedule;
