import React, { useState, useEffect } from "react";
import { useAuth0 } from "../../utils/authentication";
import { CaretDownOutlined, HomeOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Affix, Dropdown, Menu } from "antd";
import Logo from "../../assets/images/salestribe-brand.svg";
import { Link } from "react-router-dom";
import Routes from "../../constants/Routes";

const NavBar = ({ enterprise = false }) => {
  const {
    logout,
    loading,
    isAuthenticated,
    loginWithRedirect
  }: any = useAuth0();
  const [width, setWidth] = useState(window.innerWidth);

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <HomeOutlined />
        <Link to={Routes.HOME} style={{ display: "inline" }}>
          Home
        </Link>
      </Menu.Item>
      {/* <Menu.Item key="2">
        <Icon type="book" />
        <Link to={Routes.NEWS} style={{ display: "inline" }}>
          News
        </Link>
      </Menu.Item> */}
      <Menu.Item key="3">
        <UserOutlined />
        <Link to={Routes.ACCOUNT} style={{ display: "inline" }}>
          My Account
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item disabled={loading} key="4" onClick={() => logout()}>
        <LogoutOutlined />
        Logout
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (!isAuthenticated && !loading && loginWithRedirect)
      loginWithRedirect({});

    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, [isAuthenticated, loading, loginWithRedirect]);

  return (
    <Affix offsetTop={0}>
      <div style={{ borderBottom: "1px solid #e1e1e1", background: "white" }}>
        <nav id="nav" style={{ height: 60 }}>
          <Link to={Routes.HOME}>
            <img alt="sales tribe logo" src={Logo}></img>
          </Link>
          <div>
            {width > 500 ? (
              <>
                {!enterprise && (
                  <>
                    <Link to={Routes.HOME}>Home</Link>
                    {/* <Link
                  to={Routes.NEWS}
                  style={{ marginLeft: "20px" }}
                >
                  News
                </Link> */}
                    <Link
                      to={Routes.ACCOUNT}
                      style={{ marginLeft: "20px", marginRight: "10px" }}
                    >
                      Account
                    </Link>
                  </>
                )}
                <Button
                  type="default"
                  disabled={loading}
                  onClick={() => logout()}
                >
                  Log out
                </Button>
              </>
            ) : (
              <Dropdown overlay={menu} trigger={["click"]}>
                <Button>
                  <CaretDownOutlined />
                </Button>
              </Dropdown>
            )}
          </div>
        </nav>
      </div>
    </Affix>
  );
};

export default NavBar;
