import React from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
import CheckoutForm from "./CheckoutForm";
import config from "../../config.json";
import { AppContext } from "../../AppState";

const Checkout = () => {
  return (
    <StripeProvider apiKey={config.stripe.apiKey}>
      <Elements>
        <AppContext.Consumer>
          {value => <CheckoutForm value={value} />}
        </AppContext.Consumer>
      </Elements>
    </StripeProvider>
  );
};

export default Checkout;
