import React, { useState, useEffect } from "react";
import { Table, Button, message, Modal, Select } from "antd";
import config from "../../config.json";
const { Option } = Select;
const Papa = require("papaparse");

const ImportUsers: React.FC<any> = props => {
  return (
    <div>
      <h1>Backoffice - Import Users</h1>
      <p>
        Upload a csv with the data of your users. Column names need to be
        specifically:
      </p>
      <ul>
        <li>given_name</li>
        <li>family_name</li>
        <li>email</li>
        <li>company</li>
      </ul>
      <UserUpload token={props.token} />
    </div>
  );
};

const UserUpload: React.FC<any> = props => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [loadedUsers, setLoadedUsers] = useState([]);
  const [autoEmail, setAutoEmail] = useState("true");

  const updateData = (result: any) => {
    setUsers(result.data);
  };

  function addFile(e: any) {
    const file = e.target.files[0];
    Papa.parse(file, {
      complete: updateData,
      header: true,
      skipEmptyLines: true
    });
  }

  const uploadUsers = () => {
    setLoading(true);
    fetch(
      `${config.api.baseUrl}/bulkImport/${
        config.enterprise.enabled ? "enterprise" : "users"
      }`,
      {
        headers: {
          Authorization: `Bearer ${props.token}`,
          "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify({
          users,
          emailNotification: autoEmail
        })
      }
    )
      .then(data => {
        return data.json();
      })
      .then(response => {
        setLoading(false);
        setLoadedUsers(response.data);
        setModalVisible(true);
        message.success("The upload completed");
      });
  };

  const columns = [
    {
      title: "Given Name",
      dataIndex: "given_name",
      key: "given_name"
    },
    {
      title: "Family Name",
      dataIndex: "family_name",
      key: "family_name"
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email"
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company"
    }
  ];

  return (
    <>
      <input type="file" id="users" name="users" onChange={e => addFile(e)} />
      <br />
      <br />
      <Select
        defaultValue="true"
        style={{ width: 500 }}
        onSelect={(e: string) => setAutoEmail(e)}
      >
        <Option value="true">
          Automatically notify users via email with their login details
        </Option>
        <Option value="false">
          Do not automatically notifiy users via email with their login details
        </Option>
      </Select>
      <Button onClick={() => uploadUsers()} loading={loading}>
        Upload Users
      </Button>
      <br />
      <br />
      <h1>Preview Upload Data</h1>
      <Table columns={columns} dataSource={users} rowKey="email" />
      <SuccessModal
        visible={modalVisible}
        users={loadedUsers}
        autoEmail={autoEmail}
      />
    </>
  );
};

const SuccessModal = (props: any) => {
  const [visible, setVisible] = useState(props.visible);

  useEffect(() => {
    setVisible(props.visible);
  }, [props.visible]);

  const handleOk = () => {
    setVisible(false);
  };

  const columns = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username"
    },
    {
      title: "Password",
      dataIndex: "password",
      key: "password"
    }
  ];

  return (
    <Modal
      title="Users Created"
      visible={visible}
      onOk={handleOk}
      onCancel={handleOk}
      footer={[
        <Button key="submit" type="primary" onClick={handleOk}>
          OK
        </Button>
      ]}
    >
      <p>
        These are the generated usernames and passwords. Sending these usernames
        and passwords to users was set to <strong>{props.autoEmail}</strong>
      </p>
      <p>
        If you did not opt to automatically send the login details to the users,
        please take a copy of this information and send it to the new members.
      </p>
      <Table columns={columns} dataSource={props.users} rowKey="email" />
    </Modal>
  );
};

export default ImportUsers;
