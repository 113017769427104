import React, { FC } from "react";
import { Empty, Table, Tag } from "antd";

interface IInvoiceTableProps {
  data: any;
}

const InvoiceTable: FC<IInvoiceTableProps> = ({ data }) => {
  const { Column } = Table;
  if (!data) return <Empty />;
  return (
    <div id="invoice-table">
      {data.length >= 1 ? (
        <Table rowKey="id" dataSource={data}>
          <Column
            title="Date"
            dataIndex="created"
            key="created"
            render={date => new Date(date * 1000).toLocaleDateString()}
          />
          <Column
            title="Amount"
            dataIndex="amount_due"
            key="amount_due"
            render={amount => {
              amount /= 100;
              return amount.toLocaleString("en-AU", {
                style: "currency",
                currency: "AUD"
              });
            }}
          />
          <Column
            title="Status"
            dataIndex="paid"
            key="paid"
            render={paid => (
              <Tag color="green" key={paid}>
                {paid ? "Paid" : "Unpaid"}
              </Tag>
            )}
          />
          <Column
            title="Download"
            key="action"
            render={(text, invoice: any) => (
              <a href={invoice.invoice_pdf}>PDF</a>
            )}
          />
        </Table>
      ) : (
        <Empty></Empty>
      )}
    </div>
  );
};

export default InvoiceTable;
