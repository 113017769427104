import React from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
import UpdatePayment from "./UpdatePayment";
import config from "../../config.json";
import { AppContext } from "../../AppState";

const UpdatePaymentWrapper = () => {
  return (
    <StripeProvider apiKey={config.stripe.apiKey}>
      <Elements>
        <AppContext.Consumer>
          {value => <UpdatePayment value={value} />}
        </AppContext.Consumer>
      </Elements>
    </StripeProvider>
  );
};

export default UpdatePaymentWrapper;
