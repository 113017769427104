interface IQuestion {
  text: string;
  title: string;
  type: string;
}

const questions: Array<IQuestion> = [
  {
    text: "How long have you been in a sales role?",
    title: "Experience",
    type: "years"
  },
  {
    text: "Duration with your current employer and industry?",
    title: "Experience",
    type: "years"
  },
  {
    text:
      "Is sales your career of choice or a temporary stepping stone into something else?",
    title: "Experience",
    type: "open"
  },
  {
    text: "What is your long-term objective/goal with your sales career?",
    title: "Experience",
    type: "open"
  },
  {
    text: "Are you considered a specialist in your current industry?",
    title: "Specialisation",
    type: "closed"
  },
  {
    text: "Do your buyers look to you for advice/education?",
    title: "Specialisation",
    type: "closed"
  },
  {
    text:
      "Does your employer currently provide industry based (not product) training?",
    title: "Specialisation",
    type: "closed"
  },
  {
    text: "How do you currently keep up with industry changes?",
    title: "Specialisation",
    type: "open"
  },
  {
    text:
      "Have you ever had any formal sales training? If so, which methodology: SPIN Selling, Solution Selling, Value-Based Selling? Target Account Selling, Challenger Selling? Other?",
    title: "Knowledge",
    type: "open"
  },
  {
    text:
      "Do you believe that B2B sales has changed in recent years? If so why?",
    title: "Knowledge",
    type: "open"
  },
  {
    text: "Do you currently invest in your own personal development?",
    title: "Knowledge",
    type: "closed"
  },
  {
    text: "Have you had any training on social selling?",
    title: "Knowledge",
    type: "closed"
  },
  {
    text: "Are you clear on exactly who is your perfect fit customer/segment?",
    title: "Buying Process",
    type: "closed"
  },
  {
    text: "Have you ever mapped your buyers’ journey?",
    title: "Buying Process",
    type: "closed"
  },
  {
    text: "Do you know your Ideal Customer Profile (ICP)?",
    title: "Buying Process",
    type: "closed"
  },
  {
    text:
      "Do you currently leverage Buyer Personas to ensure that engage the right people?",
    title: "Buying Process",
    type: "closed"
  },
  {
    text:
      "Do you currently research your target buyers on digital and social platforms? If so, which ones?",
    title: "Digital & Social",
    type: "open"
  },
  {
    text: "What is your current LinkedIn SSI Score? Do you know what SSI is?",
    title: "Digital & Social",
    type: "open"
  },
  {
    text: "How many connections/followers do you currently have on LinkedIn?",
    title: "Digital & Social",
    type: "open"
  },
  {
    text:
      "Do you regularly share content (thought leadership) on LinkedIn or other platforms?",
    title: "Digital & Social",
    type: "closed"
  }
];
const length: number = questions.length;

export { questions, length };