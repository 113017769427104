import React, { FC } from "react";
import { Input, Typography, Button } from "antd";
const { TextArea } = Input;

interface IAssessmentInputProps {
  number: number;
  text: string;
  placeholder: string;
  next: (e: any) => void;
  handleInput: (e: any) => void;
  value: string;
  back: () => void;
}

const AssessmentInput: FC<IAssessmentInputProps> = ({
  number,
  text,
  placeholder,
  next,
  handleInput,
  value,
  back
}) => {
  return (
    <div className="form-input">
      <form onSubmit={e => next(e)} className="assessment-question">
        <div>
          <Typography.Title level={3}>
            <span
              style={{
                color: "rgba(145, 200, 72, 0.9)",
                marginRight: "25px",
                fontSize: "32px"
              }}
            >
              {number}
            </span>
            {text}
          </Typography.Title>
        </div>
        <div>
          <TextArea
            autoFocus
            onChange={e => handleInput(e)}
            value={value}
            placeholder={placeholder}
            required
            rows={4}
            style={{resize: 'none'}}
          />
        </div>
        <div style={{marginTop:'10px'}}>
          <Button htmlType="submit" type="primary">
            {number === 20 ? "Submit" : "Next"}
          </Button>
          {number - 1 >= 1 && (
            <Button onClick={() => back()} type="default">
              Prev
            </Button>
          )}
          {/* {value.length >= 1 && (
            <span style={{ fontSize: "12px" }}>
              press <b>enter</b>
            </span>
          )} */}
        </div>        
      </form>
    </div>
  );
};

export default AssessmentInput;
